<template>
  <!-- 创新赛简介 -->
  <div>
    <Footer></Footer>
    <div class="content">
      <!-- 左侧边栏 -->
      <div class="left">
        <div style="height: 3rem;margin-top: .6rem;margin-left: .4rem;">
          <el-steps direction="vertical" :active="num">
            <el-step  @click="step('1')" title="省赛初审提交"></el-step>
            <el-step  @click="step('2')" title="省赛晋级"></el-step>
            <el-step  @click="step('3')" title="国赛资料下载"></el-step>
            <el-step  @click="step('4')" title="国赛参赛材料上传"></el-step>
          </el-steps>
        </div>
      </div>
      <!-- 右边页面 -->
      <div class="right">
        <!-- 省赛初审提交 -->
        <div v-show="step1">
          <div class="box">
            <p class="box_p" style="margin-top: .6rem;">“大唐杯”全国大学生新一代信息通信技术大赛</p>
            <p class="box_p">&emsp;产教融合5G+创新应用设计赛·省赛初审材料提交</p>
            <p class="box_p" style="font-size: .22rem;margin-top: .2rem;">省赛初赛材料提交须知</p>
            <p style="margin-top: .4rem;" class="box_s">1、产教融合5G+创新应用设计赛省赛方案由各参赛队伍队长进行提交。</p>
            <p class="box_s">2、上传文件须为省赛设计方案提交模板要求的word文档，大小不限，请按照格式命名：赛项X-省份-学校名称-队伍名称</p>
            <p class="box_s">3、产教融合5G+创新应用设计赛国赛入围名单后续将择期公布，届时请关注官网公示文件。</p>
            <p class="box_s">4、国赛开发设计资源包将面向省赛晋级队伍发放，晋级后在本页进行下载。</p>
          </div>
          <div class="iframe" v-show="upload">
            <div style="height: 2rem;text-align: center;">
              <el-button type="info" disabled class="upload_but">点击选择省赛初审资料</el-button>
              <el-button type="info" disabled class="upload_but2">确认上传</el-button>
              <a id="record" @click="record()">上传记录>>></a>
            </div>
          </div>

          <div class="iframe" v-show="iframe">
            <iframe style="border: none;position: relative;" src="/static/oss_upload/index.html" ref="iframe" width="100%"
              height="200px" scrolling="no"></iframe>
          </div>
        </div>

        <!-- 省赛晋级页面 -->
        <div class="box" v-show="step2">
          <img style="width: 12rem;" :src="Asunda" alt="">
        </div>

        <!-- 国赛资料下载页面 -->
        <div v-show="step3">
          <img style="width: 12rem;" :src="map" alt="">
          <div class="box_2">
            <img class="download_2" @click="download()" style="cursor: pointer;" src="./innovation_img/download.png"
              alt="">
            <span class="box_text" @click="download()">创新赛资料包下载</span>
            <div class="back">
              <p class="box_text2">
                <span>用户名：{{ user_name }}</span>
                &emsp;
                <span style="padding-right: .2rem;">密码：{{ password }}</span>
              </p>
            </div>
            <p class="box_text3">注:此用户名、密码为资料包内破解工具使用请妥善保存</p>
          </div>
        </div>

        <!-- 国赛上传 -->
        <div v-show="step4">
          <div class="box">
            <p class="box_p" style="margin-top: .6rem;font-weight: 600;">“大唐杯”全国大学生新一代信息通信技术大赛</p>
            <p class="box_p" style="font-weight: 600;">&emsp;产教融合5G+创新应用设计赛·国赛作品提交</p>
            <p class="box_p" style="font-size: .2rem;font-weight: 700;">上传须知</p>
            <!-- <p class="box_p" style="font-size: .2rem;">国赛作品以压缩包形式上传,将下列内容打包为一个文件</p> -->
            <p class="box_p" style="font-size: .2rem;">命名方式：赛项X- XX省-XX大学（学院）-队名-队长姓名.rar</p>
            <p style="margin-top: 0.1rem;" class="box_s">1、作品设计方案，命名规则:赛项X- XX省-XX大学（学院）-队名-队长姓名.docx</p>
            <p class="box_s">2、验收演示文件，文件命名规则:赛项X- XX省-XX大学（学院）-队名-队长姓名.pptx</p>
            <p class="box_s">3、产品演示视频，命名规则:赛项X- XX省-XX大学（学院）-队名-队长姓名.mp4</p>
            <p class="box_s">4、作品源代码压缩包，命名规则:赛项X- XX省-XX大学（学院）-队名-队长姓名.zip/rar</p>
          </div>
          <div class="iframe" v-show="upload1">
            <div style="height: 2rem;text-align: center;">
              <el-button type="info" disabled class="upload_but">国赛作品提交</el-button> 
              <el-button type="info" disabled class="upload_but2">确认上传</el-button>
              <a id="record" @click="record()">上传记录>>></a>
            </div>
          </div>

          <div class="iframe" v-show="iframe1">
            <iframe style="border: none;position: relative;" src="/static/oss_upload/index.html" name= "myframe" ref="iframe" width="100%"
              height="200px" scrolling="no"></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- 版权所有 -->
    <div class="copyright">
      <div class="copyright_hurdle">
        <ul>
          <li @click="navigation('大赛首页')">大赛首页</li>
          <li>|</li>
          <li @click="navigation('赛事介绍')">赛事介绍</li>
          <li>|</li>
          <li @click="navigation('官方文件')">官方文件</li>
          <li>|</li>
          <li @click="navigation('赛区规划')">赛区规划</li>
          <li>|</li>
          <li @click="navigation('大赛培训')">大赛培训</li>
          <li>|</li>
          <li @click="navigation('联系我们')">联系我们</li>
        </ul>
      </div>
      <p class="copyright_p">Copyright 2023,中信科移动</p>
    </div>

    <!-- 弹出框 -->
    <div>
      <el-dialog v-model="dialogSwitch" title="上传文件记录" width="60%">
        <el-table :data="tableData" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
          <el-table-column prop="create_time" label="时间" sortable width="300">
          </el-table-column>
          <el-table-column prop="file_name" label="文件名称" sortable>
          </el-table-column>
        </el-table>
        <el-pagination class="Page" background @current-change="handleCurrentChange" :page-size="10"
          :hide-on-single-page="value" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import { ElMessage } from 'element-plus'
import $ from "jquery";
import { getLogin } from "api/apis.js";
export default {
  name: 'Home',
  components: {
    Footer,
    Bottom
  },
  data() {
    return {
      Asunda: require("./innovation_img/verify.jpg"),
      map: require("./innovation_img/map.jpg"),
      num: "1",
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      iframe: true,
      upload: false,
      iframe1: true,
      upload1: false,
      dialogSwitch: false,
      name: "",
      url: "",
      tableData: "",
      total: "",
      page: "1",
      limit: "",
      value: "",
      user_name: "",
      password: "",
      isDisableBtn:false,
      fileUrl:'',
      proCityType:'',
    }
  },
  created() {
    var _this = this;
    //这里可放到全局，提供给子 iframe 调用
    window.addTab = function (obj, url) {
      console.log(obj,'ddd')
      _this.name = obj
      _this.url = url
      console.log(_this.url)
      console.log(_this.$store.state.User.user_id)
      _this.getHttpRelut()
      window.vueDefinedMyProp = function(){
        
      }
    };
    //调取html方法
    // window.vueDefinedMyProp = function(){
    //   if(_this.url =='') {
    //     ElMessage({
    //                 message: "请上传文件",
    //                 type: "error",
    //             });
    //     return
    //   }else {
    //     _this.getHttpRelut();
    //   }
      
    // }

    window.addrecord = function () {
      _this.record()
    };
  },
  mounted() {
    var f = sessionStorage.getItem('member_id')
    if (f == null) {
      this.$message.error('请您先进行身份验证！');
      setTimeout(() => {
        this.$router.push("/innovate");
      }, 3000);
    }
    this.timeVerify();
    // console.log(this.$store.state.verify.innovate_province_promoted_sign, '省赛是否晋级')
  },
  methods: {
    getHttpRelut(res) {
      //业务逻辑
      let dataobj = new FormData();
      dataobj.append("user_id", sessionStorage.getItem('member_id'));
      dataobj.append("file_name", this.name);
      dataobj.append("url", this.url);
      getLogin.record(dataobj).then((res) => {
        console.log(res);
        this.open()
      });
    },
    // 打开弹框
    open() {
      this.$confirm('您已上传成功，请等待评审结果!', '提示', {
        confirmButtonText: '回到首页',
        cancelButtonText: '取消',
        customClass: 'messageBox',
        type: 'warning'
      }).then(() => {
        this.$message({
          message: '返回首页成功！',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push("/HomePage");
        }, 3000);
      }).catch(() => {

      });
    },

    // 点击分页
    handleCurrentChange(val) {
      this.page = val
      this.record()
    },
    // 获取上传记录
    record() {
      this.dialogSwitch = true
      let dataobj = new FormData();
      dataobj.append("user_id", sessionStorage.getItem('member_id'));
      dataobj.append("page", this.page);
      dataobj.append("limit", 10);
      getLogin.Document(dataobj).then((res) => {
        this.total = res.count
        let data = JSON.parse(res.data)
        this.tableData = data
        console.log(this.tableData)
      });
    },
    // 步骤条
    step(val) {
      var _that = this
      if (this.$store.state.verify.innovate_code == '1') {
        return false
      }
      if (val == '1') {
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.proCityType = 'province'
        sessionStorage.setItem('proCityType', this.proCityType);
        this.gethtmlmethod('省赛');
      } else if (val == '2' && this.num > '1') {
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
      } else if (val == '3' && this.num > '2') {
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.account();
      } else if(val == '4' && this.num > '3') {
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;
        this.proCityType = 'country'
        sessionStorage.setItem('proCityType', this.proCityType);
        this.gethtmlmethod('国赛');
      }
    },
    gethtmlmethod(name){
    window.frames["myframe"].getCompetitionName(name)

    },
    // 获取用户名密码
    account() {
      console.log(this.num, '步骤条停留在哪一步')

        let dataobj = new FormData();
        dataobj.append("member_id", sessionStorage.getItem('member_id'));
        getLogin.secret_key(dataobj).then((res) => {
          console.log(JSON.parse(res.data))
          let data = JSON.parse(res.data)
          this.user_name = data.user_name
          this.password = data.password
        });
    },
    //资料包下载
    download() {
      console.log(this.num, '步骤条停留在哪一步')
      if (this.num == '3') {
        // window.open(
        //   "https://picture-file-public-read-dt.oss-cn-hangzhou.aliyuncs.com/innovate/download/%E2%80%9C%E5%A4%A7%E5%94%90%E6%9D%AF%E2%80%9D%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E6%96%B0%E4%B8%80%E4%BB%A3%E4%BF%A1%E6%81%AF%E9%80%9A%E4%BF%A1%E6%8A%80%E6%9C%AF%E5%A4%A7%E8%B5%9B5G%2B%E5%88%9B%E6%96%B0%E5%BA%94%E7%94%A8%E4%BB%BF%E7%9C%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B.zip");
      } 
      this.getDownFileUrl()
    },
    // 底部导航
    navigation(val) {
      if (val == '大赛首页') {
        this.$router.push("/HomePage");
      } else if (val == '赛事介绍') {
        this.$router.push("/Brief");
      } else if (val == '官方文件') {
        this.$router.push("/File");
      } else if (val == '赛区规划') {
        this.$router.push("/fingerpost");
      } else if (val == '大赛培训') {
        this.$router.push("/Train");
      } else if (val == '联系我们') {
        this.$router.push("/Contact");
      }
    },

    //时间校验
    timeVerify(){
      var member_id = sessionStorage.getItem('member_id') || '';
      if(member_id){
        let dataobj = new FormData();
        dataobj.append("member_id", member_id); 
        getLogin.verifyTime(dataobj).then((res=>{
          if(res.code == 200){
            const obj = JSON.parse(res.data)
            this.$store.commit('UPverify', obj)
            console.log(obj);
             //进度条停留在哪一步
            this.num = JSON.stringify(this.$store.state.verify.innovate_code)
            if (this.num == '1') {
              this.step1 = true;
              this.step2 = false;
              this.step3 = false;
              this.step4 = false;
              this.proCityType = 'province'
              sessionStorage.setItem('proCityType', this.proCityType);
            } else if (this.num == '2') {
              this.step1 = false;
              this.step2 = true;
              this.step3 = false;
              this.step4 = false;
            } else if (this.num == '3') {
              this.step1 = false;
              this.step2 = false;
              this.step3 = true;
              this.step4 = false;
              this.account()
            } else if (this.num =='4') {
              this.step1 = false;
              this.step2 = false;
              this.step3 = false;
              this.step4 = true;
              this.proCityType = 'country'
              sessionStorage.setItem('proCityType', this.proCityType);
            } else if (this.num =='0') {
              this.step1 = false;
              this.step2 = false;
              this.step3 = false;
              this.step4 = false;
            }
            console.log(this.num, '步骤条停留在哪一步')
            
            //省赛是否提交
            if (this.$store.state.verify.innovate_province_submit_sign == true) {
              // setTimeout(() => {
              //   this.gethtmlmethod('省赛')
              // }, 500);
              this.iframe = true
              this.upload = false
            } else if (this.$store.state.verify.innovate_province_submit_sign == false) {
              this.iframe = false
              this.upload = true
            }
            // console.log(this.$store.state.verify.innovate_province_submit_sign, '省赛是否提交')

            //省赛是否晋级
            if (this.$store.state.verify.innovate_province_promoted_sign == true) {
              this.Asunda = require("./innovation_img/succeed.jpg");
            } else if (this.$store.state.verify.innovate_province_promoted_sign == false) {
              this.Asunda = require("./innovation_img/error.jpg");
            }

            if (this.$store.state.verify.innovate_country_submit_sign == true) {
              this.iframe1 = true
              this.upload1 = false
            } else if (this.$store.state.verify.innovate_country_submit_sign == false) {
              this.iframe1 = false
              this.upload1 = true
            }}else if(res.code == 0){
                    this.$message.error(res.msg);
                    this.$router.push("/innovate");
            }
        }))
      }
    },

    //创新赛文件获取下载的url
    getDownFileUrl(){
      let member_id = sessionStorage.getItem('member_id') || '';
      let group = sessionStorage.getItem('group') || '';
      let dataobj = new FormData();
      dataobj.append("member_id", member_id); 
      dataobj.append("group", group); 
      getLogin.verifyFileUrl(dataobj).then((res)=>{
        if(res.code == 200){
          this.fileUrl = res.url;
          window.open(this.fileUrl)  
        }else{
          this.$message.error(res.msg);
        }
      })
    }
  },
}
</script>
  
<style  lang="scss">
.messageBox {
  position: relative;
  bottom: 2rem;

  .el-message-box__header {
    background: #d7edfb !important;
    margin-right: 0 !important;
  }

}
</style>

<style scoped lang="scss">
:deep .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}

@import './step.scss'
</style>